module.exports = {
  toLayout(to, config) {
    let val = "default";

    if (to.path.includes(config.news)) {
      val = "news"
    }

    if (
      to.path.includes(config.terraXPage)
    ) {
      val = "terraX"
    }

    if (
      to.path.includes(config.terraXProjectsPage) ||
      to.path.includes(config.terraXInvestorsPage) ||
      to.path.includes(config.terraXInsurancePage)
    ) {
      val = "terraXFind"
    }

    if (
      to.path.includes(config.terraXProjects) ||
      to.path.includes(config.terraXInvestors) ||
      to.path.includes(config.terraXInsurance)
    ) {
      val = "terraXArticle"
    }

    return val

  },
  fromLayout(from, config) {
    let val = "default";

    if (from.path.includes(config.news)) {
      val = "news"
    }

    if (
      from.path.includes(config.terraXPage)
    ) {
      val = "terraX"
    }

    if (
      from.path.includes(config.terraXProjectsPage) ||
      from.path.includes(config.terraXInvestorsPage) ||
      from.path.includes(config.terraXInsurancePage)
    ) {
      val = "terraXFind"
    }

    if (
      from.path.includes(config.terraXProjects) ||
      from.path.includes(config.terraXInvestors) ||
      from.path.includes(config.terraXInsurance)
    ) {
      val = "terraXArticle"
    }

    return val
  }
}
