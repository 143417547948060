<template>
  <components
    v-if="story.content"
    :key="story.id"
    :blok="story.content"
    :name="story.name"
    :is="story.content.component"
  />
</template>

<script>
import LayoutAstra from "../components/site/LayoutAstra.vue";
import LayoutDefault from "~/components/site/LayoutDefault.vue";
import LayoutShorthand from "~/components/site/LayoutShorthand.vue";
import LayoutSeal from "~/components/site/LayoutSeal.vue";
import LayoutProject from "~/components/site/LayoutProject.vue";
import LayoutInvestor from "~/components/site/LayoutInvestor.vue";
import LayoutInsurance from "~/components/site/LayoutInsurance.vue";
import LayoutNewsArticle from "@/components/site/LayoutNewsArticle.vue";
import LayoutNewsArchive from "@/components/site/LayoutNewsArchive.vue";
import LayoutTestimonials from "@/components/site/LayoutTestimonials.vue";

import LayoutTerraX from "@/components/site/LayoutTerraX.vue";
import LayoutTerraXFind from "~/components/site/LayoutTerraXFind.vue";

import server from "~/helpers/server.js";
import help from "~/helpers/transitions.js";

export default {
  transition(to, from) {
    let name = "page";
    let mode = "out-in";

    let toLayout = help.toLayout(to, $nuxt.$config);
    let fromLayout = "default";
    if (from) {
      fromLayout = help.toLayout(from, $nuxt.$config);
    }

    // News in
    if (toLayout === "news") {
      name = "newsIn";
      mode = undefined;
    }
    // News out
    if (fromLayout === "news") {
      name = "newsOut";
      mode = undefined;
    }

    // Terra x article in
    if (toLayout === "terraXArticle") {
      name = "page";
      mode = "out-in";
      $nuxt.$store.commit("terrax/globeHideIt");
    }
    // Terra x article out
    if (fromLayout === "terraXArticle" && toLayout === "terraXFind") {
      name = "terrax-article-out";
      mode = "out-in";
      $nuxt.$store.commit("terrax/globeShowIt");
    }

    // Terra x out
    if (
      (fromLayout === "terraXFind" && toLayout === "default") ||
      (fromLayout === "terraX" && toLayout === "default")
    ) {
      name = "page";
      mode = "out-in";
      $nuxt.$store.commit("terrax/globeHideIt");
    }

    // Terra x other pages
    if (
      (toLayout === "terraXFind" && fromLayout === "default") ||
      (toLayout === "terraX" && fromLayout === "default")
    ) {
      name = "page";
      mode = "out-in";
      $nuxt.$store.commit("terrax/globeShowIt");
    }
    // Terra x to terra x
    if (
      (fromLayout === "terraXFind" && toLayout === "terraXFind") ||
      (fromLayout === "terraXFind" && toLayout === "terraX") ||
      (fromLayout === "terraX" && toLayout === "terraXFind")
    ) {
      name = "nope";
      mode = "";
      $nuxt.$store.commit("terrax/globeShowIt");
    }
    // Terra x bg out
    if (fromLayout === "terraX" && toLayout === "terraXFind") {
      // This is mega hacky, but it works when generated..
      if (to.path.includes($nuxt.$config.terraXInsurancePage)) {
        name = "terrax-insurence";
        mode = "out-in";
        document.body.classList.add("globe-insurance");
        setTimeout(() => {
          document.body.classList.remove("globe-insurance");
        }, 1500);
      } else {
        name = "terrax-bg-in";
        mode = "out-in";
      }
    }

    // Terra x bg in
    if (fromLayout === "terraXFind" && toLayout === "terraX") {
      name = "terrax-bg-out";
      mode = "out-in";
    }

    // Get terrax data if not there
    if (
      toLayout === "terraX" ||
      toLayout === "terraXFind" ||
      toLayout === "terraXArticle"
    ) {
      // some get data & check if data
    }
    return {
      name,
      mode,
      afterLeave: function(el) {
        this.$root.$emit("page-after-leave");
      }
    };
  },
  head() {
    return {
      title:
        this.story.content.Seo.title || this.story.name || "page not found",
      meta: [
        {
          key: "description",
          name: "description",
          content: this.story.content.Seo.description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.story.full_slug
        },
        {
          property: "og:title",
          content: this.story.content.Seo.title || this.story.name
        },
        {
          property: "og:description",
          content: this.story.content.Seo.description
        },
        {
          property: "og:image",
          content: this.story.content.thumbnail
            ? this.transformImage(
                this.story.content.thumbnail.filename,
                "1200x630" + "/smart/filters:format(jpg)"
              )
            : ""
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.story.full_slug
        },
        {
          property: "twitter:title",
          content: this.story.content.Seo.title || this.story.name
        },
        {
          property: "twitter:description",
          content: this.story.content.Seo.description
        },
        {
          property: "twitter:image",
          content: this.story.content.thumbnail
            ? this.transformImage(
                this.story.content.thumbnail.filename,
                "1200x630" + "/smart/filters:format(jpg)"
              )
            : ""
        },
        {
          property: "twitter:image:alt",
          content: this.story.content.thumbnail.alt
        }
      ]
    };
  },
  components: {
    LayoutAstra,
    LayoutDefault,
    LayoutShorthand,
    LayoutSeal,
    LayoutProject,
    LayoutInvestor,
    LayoutInsurance,
    LayoutNewsArchive,
    LayoutNewsArticle,
    LayoutTestimonials,
    LayoutTerraX,
    LayoutTerraXFind
  },
  data() {
    return {
      boom: 1,
      story: { content: {} },
      news: []
    };
  },
  mounted() {
    this.$store.commit("setIsNotInitialPageLoad", true);
  },
  methods: {
    transformImage(image, option) {
      if (!image) return "";
      if (!option) return "";

      let imageService = "//img2.storyblok.com/";
      let path = image.replace("//a.storyblok.com", "");
      return imageService + option + path;
    }
  },
  async asyncData(context) {
    const { route } = context;

    const version = server.getVersion(context);
    const fullSlug = server.getSlug(context);

    const page = await server.api(context.app, version, fullSlug, context);

    // Check if body compoments
    // If body component get data
    let news = [];
    if (page.content.component === "LayoutNewsArchive") {
      news = await server.getAllNews(context.app, version);
    }

    let testimonials = [];
    if (page.content.component === "LayoutTestimonials") {
      testimonials = await server.getAllCeoTestimonials(context.app, version);
    }

    let supporters = {};
    if (page.content.body) {
      for (const item of page.content.body) {
        if (item.component == "CompNewsArchive") {
          if (news.length < 1) {
            news = await server.getAllNews(context.app, version);
          }
        } else if (item.component == "CompSupporters") {
          const labelToSlug = item.label.replace(/\s+/g, "-").toLowerCase();
          supporters = await server.api(
            context.app,
            version,
            `global/list/supporterss/${labelToSlug}`,
            context
          );
          //console.log("first", supporters);
        }
      }
    }

    if (
      page.content.component === "LayoutTerraX" ||
      page.content.component === "LayoutTerraXFind"
    ) {
      const $store = context.app.store;

      if ($store.state.terrax.projects.length === 0) {
        // console.log("_VUE", "Get TerraX PROJECTS");
        const terraXProjects = await server.getAllTerraX(
          context.app,
          version,
          context.$config.terraXProjects,
          context
        );
        await $store.commit("terrax/setTerraXProjects", terraXProjects);
      }
      if ($store.state.terrax.investors.length === 0) {
        // console.log("_VUE", "Get TerraX INVESTORS");
        const terraXInvestors = await server.getAllTerraX(
          context.app,
          version,
          context.$config.terraXInvestors,
          context
        );
        await $store.commit("terrax/setTerraXInvestors", terraXInvestors);
      }
      if ($store.state.terrax.insurance.length === 0) {
        // console.log("_VUE", "Get TerraX INSURANCE");
        const terraXInsurance = await server.getAllTerraX(
          context.app,
          version,
          context.$config.terraXInsurance,
          context
        );
        await $store.commit("terrax/setTerraXInsurance", terraXInsurance);
      }
    }

    route.meta.push({ layout: page.content.component });

    // IMPORTANT: wait with setting layout until the page transition has happened - unless it's the very first page load
    const isNotInitialPageLoad = context.store.state.isNotInitialPageLoad;
    if (isNotInitialPageLoad) {
      $nuxt.$root.$once("page-after-leave", () => {
        context.store.commit("setLayout", page.content.component);
      });
      // setTimeout(() => {
      //   context.store.commit("setLayout", page.content.component);
      // }, 500);
    } else {
      context.store.commit("setLayout", page.content.component);
    }
    const createBody = await server.buildData(
      page,
      news,
      supporters,
      testimonials
    );
    return {
      story: createBody
    };
  }
};
</script>
